import React from 'react';
import { graphql } from 'gatsby';
import {
  Intro,
  Swirl,
  Section,
  Prefooter,
  Grid,
  Card,
  CardList,
  Content
} from '../partials/components';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { getSpecsFromProduct } from '../helpers/productHelpers';
import { PageTransition } from '../helpers/pageTransitions';
import { FaChevronRight } from 'react-icons/fa';
import { t } from '../helpers/language';
import { getColor } from '../helpers/colors';

const ProductCollection = ({
  data: { products, collection, datoCmsConfig },
  pageContext
}) => {
  const {
    title,
    leadText,
    leadHeader,
    seoMetaTags,
    extendedContentNode,
    pathways,
    showPathways
  } = collection;
  const { contactPage, defaultPathways } = datoCmsConfig;

  const allPathways = [...pathways, ...defaultPathways].slice(0, 3);

  return (
    <>
      <PageTransition>
        <HelmetDatoCms seo={seoMetaTags} />
        <Section>
          <Intro
            title={title}
            breadcrumbs={pageContext.breadcrumbs}
            action={{
              iconAfter: <FaChevronRight />,
              gaTarget: 'spa-chooser-cta',
              children: t('spa_chooser_cta'),
              to: '/spa-chooser'
            }}
          >
            <Swirl toColor={getColor('light-gray')} />
          </Intro>
        </Section>

        <Section
          animate={true}
          lead={leadText}
          heading={leadHeader}
          padding={'double'}
          background={'light-gray'}
        >
          <Grid>
            {products.nodes.map((node, i) => {
              return (
                <Card
                  key={`product-${i}`}
                  heading={node.title}
                  caption={node.shortDescription}
                  items={getSpecsFromProduct(node)}
                  image={node.heroImage}
                  links={[
                    {
                      path: node.path,
                      title: t('generic_cta')
                    },
                    {
                      path: contactPage.path,
                      gaTarget: 'product-enquiry-cta',
                      state: {
                        product: node
                      },
                      title: t('spaworld_contact_cta')
                    }
                  ]}
                  buttonsRight={true}
                />
              );
            })}
          </Grid>
        </Section>
        {extendedContentNode && (
          <Section padding={'both'} background={'light-gray'}>
            <Content contentNode={extendedContentNode} />
          </Section>
        )}

        {showPathways && (
          <Section animate={true} background={'light-gray'} padding={'both'}>
            <CardList fullWidth={true}>
              {allPathways.map((pathway, i) => (
                <Card key={`pathway-${i}`} {...pathway} />
              ))}
            </CardList>
          </Section>
        )}
        <Section animate={true} background={'light-gray'} padding={'top'}>
          <Prefooter contactPagePath={contactPage.path} />
        </Section>
      </PageTransition>
    </>
  );
};

export const query = graphql`
  query collectionById($id: String, $tagId: String, $language: String) {
    collection: datoCmsCollection(id: { eq: $id }) {
      slug
      leadText
      leadHeader
      title
      path
      tag {
        name
        slug
        id
      }
      parentLandingPage {
        title
        slug
      }
      extendedContentNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pathways {
        ...Card
      }
      showPathways
    }
    datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }

    products: allDatoCmsProduct(
      sort: { fields: title, order: DESC }
      filter: {
        tags: { elemMatch: { id: { eq: $tagId }, locale: { eq: $language } } }
      }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

export default ProductCollection;
